<template>
	<div>
		<!-- <el-dialog :title="$t('i18nn_24c8f46012a25c89')" append-to-body :close-on-click-modal="false" :visible.sync="dialogAddVisible"
			width="1000px" top="0"> -->
		<el-drawer :wrapperClosable="false" :title="!!id?$t('i18nn_6267f3aedf895209'):$t('i18nn_24c8f46012a25c89')" append-to-body :visible.sync="dialogAddVisible" :direction="'rtl'" size="1000px">
			<!-- <div style=""> -->
			<!-- <div> -->
			<el-card style="" v-loading="loading">
				<div slot="header">
					<h3>{{$t('i18nn_f80dedf7c601059f')}}</h3>
				</div>
				<el-form :model="form" label-width="100px" v-loading="loading_load">
					<!-- <div> -->
					<el-form-item :label="$t('i18nn_c944a6686d996ab3')" prop="" required>
						<whNoSelect ref="whNoSelect" @changeData="changWhNo"></whNoSelect>
					</el-form-item>
					
					<el-form-item :label="$t('i18nn_7431e76029678ec7')" prop="" required>
						<el-input type="text" v-model="form.title"></el-input>
					</el-form-item>

					<div style="margin: 20px 0;">
						<el-button @click="addLe2" type="primary" size="small" icon="el-icon-plus">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
						
						<!-- <div style="margin-bottom: 10px;">
							<el-button type="primary" size="small" icon="el-icon-plus"
								@click="addRow">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
							<el-button type="danger" size="small" icon="el-icon-delete"
								@click="clearRow">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button>
						</div> -->

						<el-table :data="form.addServiceList" stripe :border="true" style="width: 100%" size="small">
							<el-table-column type="index" width="50" align="center" :label="$t('7b1c2b1adc920d9c')">
							</el-table-column>
							<el-table-column prop="serviceType" :label="$t('i18nn_f1d6776242105f03')">
								<template slot-scope="scope">
									<el-select filterable clearable size="small" v-model="scope.row.serviceType"
										:placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 200px;">
										<el-option v-for="(item,index) in selectOption.wh_op_add_service" :key="index"
											:label="$Utils.i18nCodeText(item)" :value="item.code">
											<div class="sel_option_s1" style="">
												<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
												<span class="sel_option_code" style="">{{ item.code }}</span>
											</div>
										</el-option>
									</el-select>
								</template>
							</el-table-column>
							
							
							<el-table-column prop="quantity" :label="$t('i18nn_f9db93b87e08763b')+'(和'+$t('i18nn_f1d6776242105f03')+'相关)'">
								<template slot-scope="scope">
									<el-input-number v-model="scope.row.quantity" size="small" controls-position="right"
										style="width: 200px;"></el-input-number>
								</template>
							</el-table-column>
							
							<el-table-column prop="goodsSku" :label="'SKU'+'(非必填)'">
								<template slot-scope="scope">
									<div>
										<el-button type="primary" size="small" icon="el-icon-magic-stick"
											@click="addRow($event,scope.$index)">{{$t('i18nn_7fb6ada66a1fccca')}}</el-button>
											<!-- <el-input v-model="scope.row.goodsSku" size="small"
												style="width: 200px;"></el-input> -->
											<span>{{scope.row.goodsSku}}</span>
									</div>
								</template>
							</el-table-column>
							
							<el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="200px">
								<template slot-scope="scope">
									<el-button @click="delLe2($event, scope.$index,scope.row)" type="warning" size="small"
										icon="el-icon-minus">{{ $t('i18nn_e33c9b93c36fd250') }}</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>

					<el-form-item :label="$t('i18nn_15b3627faddccb1d')" prop="" required>
						<el-input type="textarea" v-model="form.remark" :maxlength="2000" show-word-limit ></el-input>
					</el-form-item>
					
					<el-form-item :label="$t('i18nn_f5d43732e3f6cf4d')" prop="">
						<div v-loading="loadingUploadFile">
							<el-button type="primary" icon="el-icon-paperclip" @click="openAddFile()">{{$t('i18nn_ad36057ff6a7ce6b')}}</el-button>
							<ul>
								<li v-for="(item,index) in form.attachments" :key="index">
									<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999"
										:fit="'contain'" :src="item.url" :preview-src-list="form.attachments.map(itemPre=> { return itemPre.url})">
										<div slot="error" class="image-slot">
											<i class="el-icon-document"></i>
										</div>
									</el-image>
									<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>
									
									<el-button @click="delFileAction($event, item,index)" type="text" size="mini"
										icon="el-icon-delete" style="padding: 0 10px;" v-if="item.id">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
									<el-button @click="delFile($event, index)" type="text" size="mini" icon="el-icon-delete"
										style="padding: 0 10px;" v-else>{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
										
								</li>
							</ul>
						</div>
					</el-form-item>
				</el-form>
			</el-card>


			<!--  -->

			<!-- <el-button type="primary" @click="submitForm()">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button> -->
			<!-- </div> -->
			<!-- </div> -->
			<div class="drawer-footer">
				<el-button type="primary" icon="el-icon-tickets" @click="submitForm()">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button>
				<!-- <el-button type="primary" plain @click="dialogAddVisible = false">{{ $t('i18nn_4e9fc68608c60999') }}
				</el-button> -->
			</div>
		</el-drawer>
		<!-- </el-dialog> -->
		
		<!-- 选择SKU -->
		<dialogWSku :openTime="skuInvOpenTime" @sure="sureSel"></dialogWSku>

		<!-- 附件 -->
		<dialogFileUpload ref="dialogFileUpload" :folder="'addValService/'"
			:openTime="FileUploadOpenTime" @success="FileUploadSuccess"></dialogFileUpload>

	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	
	import dialogWSku from '@/components/StorageCenter/WSkuProduct/dialogWSku.vue';
	
	import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';

	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	export default {
		props: {

			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			id: {
				default: function() {
					return '';
				},
				type: String
			}
			// relationId: {
			//   default: function() {
			//     return '';
			//   },
			//   type: String
			// }
		},
		components: {
			whNoSelect,
			dialogFileUpload,
			dialogWSku
		},
		data() {
			return {
				loading: false,
				loading_load: false,
				dialogAddVisible: false,

				FileUploadOpenTime: '',
				
				skuInvOpenTime: '',
				skuOpenIndex:'',
				
				loadingUploadFile: false,
				
				form: {
					"remark": "",
					title:"",
					whNo: "",
					attachments: [],
					"addServiceList": [
						// {
						// 	"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
						// 	"goodsSku":"",
						// 	"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
						// },
					]
				},

				selectOption: {
					wh_op_add_service: [],
				},
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogAddVisible = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();

		},
		//编译挂载前
		mounted() {
			// this.initData();
			// this.getDicData();
			//数据字典
			getDicData(['wh_op_add_service'],
				(data)=>{
					this.selectOption.wh_op_add_service = data['wh_op_add_service'];
			});
		},
		methods: {
			initData() {
				this.dialogAddVisible = true;

				this.form = {
					"remark": "",
					title:"",
					whNo: "",
					attachments: [],
					"addServiceList": [
						// {
						// 	"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
						// 	"goodsSku":"",
						// 	"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
						// },
					]
				};
				this.$nextTick(() => {
					this.$refs.whNoSelect.init("");
				});
				
				if (this.id) {
					// this.tableData = [];
					// this.DetData = {};
					this.getDetPageData(this.id);
					this.getAddServiceDetPageData(this.id);
				}

			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
			},

			//新增
			addLe2() {
				this.form.addServiceList.push({
					"goodsSku":"",
					"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
					"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
				});
			},
			
			// //删除
			delLe2(event, index,row) {
				event.stopPropagation();
				// this.form.addServiceList.splice(index, 1);
				if(row.id){
					this.$message.warning("该增值已提交，请联系客服删除");
					//后端删除
					// this.loading_load = true;
					// this.$http
					//   .delete(this.$urlConfig.WhWorkAddServiceDetDel + '/' + row.id, {})
					//   .then(({ data }) => {
					//     this.loading_load = false;
					//     if (200 == data.code) {
					// 			this.form.addServiceList.splice(index, 1);
					// 			this.$forceUpdate();
					// 			this.$message.success(this.$t('i18nn_de017dafc266aa03'));
					//     } else {
					// 			this.$message.warning(data.msg ? data.msg : this.$t('i18nn_54dc58d9d0377bb5'));
					//     }
					//   })
					//   .catch(error => {
					//     console.log(error);
					// 		this.loading_load = false;
					// 		this.$message.warning(data.msg ? data.msg : '删除失败!');
					//   });
				} else {
					this.form.addServiceList.splice(index, 1);
				}
			},
			
			// 新建-表格
			addRow(event,index) {
				event.stopPropagation();
				// if(!this.form.whNo){
				// 	this.$message.warning(this.$t('FormMsg.Select_long_wh'))
				// 	return;
				// }
				// this.skuInvWhNo = this.form.senderAddr;
				this.skuInvOpenTime = new Date().getTime();
				this.skuOpenIndex = index;
			},
			// selSkuInv(val){
			// 	val.forEach(item=>{
			// 		let hasSku = this.form.addServiceList.some(item2=> item.goodsSku == item2.goodsSku);
			// 		//是否已经存在此SKU
			// 		if(!hasSku){//不存在
			// 			this.form.addServiceList.push({
			// 				goodsSku: item.goodsSku,
			// 				"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
			// 				"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
			// 			});
			// 		}
			// 	})
			// },
			sureSel(val){
				val.forEach((item,index)=>{
					// let hasSku = this.form.addServiceList.some(item2=> item.goodsSku == item2.goodsSku);
					//是否已经存在此SKU
					// if(!hasSku){//不存在
						if(0==index){//第一条直接录入
							// this.form.addServiceList[this.skuOpenIndex].goodsSku = item.goodsSku;
							let oneAdd = this.form.addServiceList[this.skuOpenIndex];
							oneAdd.goodsSku = item.goodsSku;
							this.$set(this.form.addServiceList,this.skuOpenIndex,oneAdd)
						} else {//其他插入新数据
							this.form.addServiceList.push({
								goodsSku: item.goodsSku,
								"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
								"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
							});
						}
					// }
				})
			},
			
			// clearRow(){
			// 	this.form.addServiceList = [];
			// },
			
			

			//添加附件
			openAddFile() {
				// this.FileUploadOpenIndex = index;
				this.FileUploadOpenTime = new Date().getTime();
			},
			FileUploadSuccess(data) {
				console.log('FileUploadSuccess', data);
				let fileList = data.map(item => {
					return {
						fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
						url: item.filePath, //this.$t('i18nn_8758fd50c87d6c9c')
						// type: "90"
					}
				});
				// this.form.attachments = fileList;
				this.form.attachments = this.form.attachments.concat(fileList);
				this.$forceUpdate();
			},
			//删除附件
			delFile(event, index) {
				event.stopPropagation();
				this.form.attachments.splice(index, 1);
				this.$forceUpdate();
			},
			
			//后端删除
			delFileAction(event,row,index) {
				event.stopPropagation();
			  this.loadingUploadFile = true;
			  this.$http
			    .delete(this.$urlConfig.WhFileUploadDel + '/' + row.id, {})
			    .then(({ data }) => {
			      // console.log(this.$t('i18nn_bc868e024b80d2e3'));
			      console.log(data);
			      this.loadingUploadFile = false;
			      if (200 == data.code) {
			        // this.getListData();
							this.form.attachments.splice(index, 1);
							this.$forceUpdate();
							this.$message.success(this.$t('i18nn_de017dafc266aa03'));
			      } else {
			        // this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			        //   type: 'warning'
			        //   //confirmButtonText: this.$t('204ffab8a6e01870'),
			        // });
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_54dc58d9d0377bb5'));
			      }
			    })
			    .catch(error => {
			      console.log(error);
			      // console.log(this.$t('tips.submitError'));
						this.loadingUploadFile = false;
						this.$message.warning(data.msg ? data.msg : '删除失败!');
			      
			      // this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			      //   type: 'warning'
			      //   //confirmButtonText: this.$t('204ffab8a6e01870'),
			      // });
			    });
			},
			
			// fomatterQaTypeToBizType(qaType){
			// 	// bizType
			// 	//code: "SDS", codeText: this.$t('i18nn_684a2afb069b6016'),
			// 	//code: "SRE", codeText: this.$t('i18nn_199f5bded0b23976'), 
			// 	//code: "STF", codeText: "自提/快递", 
			// 	//code: "SCC", codeText: this.$t('i18nn_c39fd3d48389bea7'), 
			// 	//code: "STR", codeText: "中转/转运"

			// 	// qaBizType
			// 	// code: "10", codeText: this.$t('i18nn_684a2afb069b6016'),
			// 	// code: "20", codeText: this.$t('i18nn_6373cb1d1204d580'),
			// 	// code: "30", codeText: this.$t('i18nn_57495c18e2baebdc'), 
			// 	// code: "40", codeText: this.$t('i18nn_21e42bbe9894aafc'), 
			// 	// code: "99", codeText: this.$t('i18nn_97e49d00bcc4c17c'),
			// 	let bizType = '';
			// 	if('10'==qaType){
			// 		bizType = 'SDS';
			// 	} else if('20'==qaType){
			// 		bizType = 'STF';
			// 	} else if('30'==qaType){//已废弃
			// 		bizType = 'STR';
			// 	} else if('40'==qaType){
			// 		bizType = 'SCC';
			// 	} else if('50'==qaType){
			// 		bizType = 'SRE';
			// 	}
			// 	return bizType;
			// },
			
			//请求分页数据
			getDetPageData(id) {
				// let _this = this;
				this.loading_load = true;
				this.$http
					// .get(this.$urlConfig.WhWorkOrderDet + '/' + id, {})
					.get(this.$urlConfig.WhWorkOrderEditDet + '/' + id, {})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						// this.form = data.data;
						// this.form = {
						// 	"remark": "",
						// 	title:"",
						// 	whNo: "",
						// 	attachments: [],
						// 	"addServiceList": [
						// 		// {
						// 		// 	"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
						// 		// 	"goodsSku":"",
						// 		// 	"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
						// 		// },
						// 	]
						// };
						if(200==data.code&&data.data) {
							this.form.id = data.data.id;
							this.form.remark = data.data.remark;
							this.form.title = data.data.title;
							
							this.form.whNo = data.data.whNo;
							this.form.attachments = data.data.attachments;
						}
						// this.$nextTick(() => {
						// 	this.$refs.whNoSelect.init("");
						// });
						this.$nextTick(() => {
							this.$refs.whNoSelect.init(this.form.whNo);
						})
						//当前数据总条数
						// this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			//请求分页数据
			getAddServiceDetPageData(id) {
				// this.tableData = [];
				// let _this = this;
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhWorkAddServiceDet + '/' + id, {})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading = false;
						if(200==data.code && data.rows){
							this.form.addServiceList = data.rows;
						}
						
						//表格显示数据
						// this.tableData = data.rows;
						//当前数据总条数
						// this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading = false;
					});
			},
			
			//提交信息
			submitForm() {
				// let bizType = this.fomatterQaTypeToBizType(this.qaType);
				let formData = Object.assign({}, this.form);
				// formData.relationId = this.relationId;
				// formData.bizType = bizType;
				this.postData(this.$urlConfig.WhValAddServiceOtherAdd, formData, '', () => {
					this.$emit('success');
					this.dialogAddVisible = false;
					
					// this.getPageData();
					// this.$message.success(this.$t('9f30371831a98237'));
				});
			},
			//提交数据
			postData(url, formData, type, callback) {
				// let _this = this;
				this.loading = true;

				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							callback();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_op_add_service'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_op_add_service = data.data['wh_op_add_service'];
			// 			} else {
			// 				// if (!data.msg) {
			// 				//   data.msg = this.$t("tips.submitError");
			// 				// }
			// 				// this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			// this.$message.error(this.$t("tips.submitRequestError"));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
